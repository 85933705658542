import React from "react";

// Chakra imports
import {
  Icon,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import {
  MdOutlineMoreHoriz,
  MdEditNote,
  MdPostAdd,
  MdFormatListBulleted,
} from "react-icons/md";
import { useForfait } from "hooks/useForfait";
import { useHistory } from "react-router-dom";

type ListMenuForfaitProps = {
  forfaitrow: any[];
  openmodal: () => void;
  setselectedforfait: (forfait: any) => void;
  setData: (data: any[]) => void;
  setFormModal: (form: string) => void;
};

export default function ListMenuForfait(
  props: ListMenuForfaitProps
): JSX.Element {
  const textColor = useColorModeValue("secondaryGray.500", "white");
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const iconColor = useColorModeValue("brand.500", "white");
  const bgList = useColorModeValue("white", "whiteAlpha.100");
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  // Ellipsis modals
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const { fetch, updateForfaitField } = useForfait();

  function handleUpdate() {
    props.setselectedforfait(props.forfaitrow);
    props.openmodal();
  }
  async function handleUpdateStatut() {
    await updateForfaitField(
      props.forfaitrow[0].id,
      "statut",
      !props.forfaitrow[0].statut
    );
    const newData: ForfaitType[] = await fetch();
    props.setData(newData);
  }
  const history = useHistory();
  const handleListeInterventions = () => {
    history.push(`/admin/interventions?idforfait=${props.forfaitrow[0].id}`);
  };
  const handleAddIntervention = () => {
    props.setselectedforfait(props.forfaitrow);
    props.setFormModal("interventionForfait");
    props.openmodal();
  };

  return (
    <Menu isOpen={isOpen1} onClose={onClose1}>
      <MenuButton
        alignItems="center"
        justifyContent="center"
        bg={bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        w="37px"
        h="37px"
        lineHeight="100%"
        onClick={onOpen1}
        borderRadius="10px"
      >
        <Icon as={MdOutlineMoreHoriz} color={iconColor} w="24px" h="24px" />
      </MenuButton>
      <MenuList
        w="250px"
        minW="unset"
        maxW="250px !important"
        border="transparent"
        backdropFilter="blur(63px)"
        bg={bgList}
        boxShadow={bgShadow}
        borderRadius="20px"
        p="15px"
      >
        <MenuItem
          transition="0.2s linear"
          color={textColor}
          _hover={textHover}
          p="0px"
          borderRadius="8px"
          _active={{
            bg: "transparent",
          }}
          _focus={{
            bg: "transparent",
          }}
          mb="10px"
          onClick={handleUpdate}
        >
          <Flex align="center">
            <Icon as={MdEditNote} h="16px" w="16px" me="8px" />
            <Text fontSize="sm" fontWeight="400">
              Modifier
            </Text>
          </Flex>
        </MenuItem>
        <MenuItem
          transition="0.2s linear"
          color={textColor}
          _hover={textHover}
          p="0px"
          borderRadius="8px"
          _active={{
            bg: "transparent",
          }}
          _focus={{
            bg: "transparent",
          }}
          mb="10px"
          onClick={handleUpdateStatut}
        >
          <Flex align="center">
            <Icon as={MdEditNote} h="16px" w="16px" me="8px" />
            <Text fontSize="sm" fontWeight="400">
              Changer le statut
            </Text>
          </Flex>
        </MenuItem>
        <MenuItem
          transition="0.2s linear"
          color={textColor}
          _hover={textHover}
          p="0px"
          borderRadius="8px"
          _active={{
            bg: "transparent",
          }}
          _focus={{
            bg: "transparent",
          }}
          mb="10px"
          onClick={handleListeInterventions}
        >
          <Flex align="center">
            <Icon as={MdFormatListBulleted} h="16px" w="16px" me="8px" />
            <Text fontSize="sm" fontWeight="400">
              Voir les interventions
            </Text>
          </Flex>
        </MenuItem>
        {props.forfaitrow[0].statut && (
          <MenuItem
            transition="0.2s linear"
            color={textColor}
            _hover={textHover}
            p="0px"
            borderRadius="8px"
            _active={{
              bg: "transparent",
            }}
            _focus={{
              bg: "transparent",
            }}
            mb="10px"
            onClick={handleAddIntervention}
          >
            <Flex align="center">
              <Icon as={MdPostAdd} h="16px" w="16px" me="8px" />
              <Text fontSize="sm" fontWeight="400">
                Ajouter une intervention
              </Text>
            </Flex>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
}
