import {
  Flex,
  Box,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spacer,
  Button,
  useDisclosure,
  Icon,
  Select,
} from "@chakra-ui/react";
import * as React from "react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

// Custom components
import Card from "components/card/Card";
import { MdAdd, MdCalendarMonth, MdHourglassBottom } from "react-icons/md";
import ModalForm from "components/modal/modalForm";
import ModalDelete from "components/modal/modalDelete";
import ForfaitStatus from "components/user/ForfaitStatus";
import Moment from "react-moment";
import FieldClient from "components/user/FieldClient";
import { numberToHour } from "utils";
import ListMenuForfait from "components/menu/ListMenuForfait";
import { useForfaitFilter } from "contexts/FilterForfaitContext";

type TableForfaitType = {
  id: string;
  idclient: string;
  date_debut: Date;
  client: string;
  prix: number;
  remarque_tech: string;
  statut: boolean;
  temps: number;
  type: number;
};

const columnHelper = createColumnHelper<TableForfaitType>();

// const columns = columnsDataCheck;
export default function ColumnTable(props: { tableData: any }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modalDelete = useDisclosure();
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [selectedForfait, setSelectedForfait] = React.useState<
    ForfaitType[] | null
  >(null);
  const [formModal, setFormModal] = React.useState("forfait");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  let defaultData = tableData;

  const columns = [
    columnHelper.accessor("date_debut", {
      id: "date_debut",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Date
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            <Moment format="DD/MM/YYYY">{info.getValue().toDate()}</Moment>
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("statut", {
      id: "statut",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Statut
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <ForfaitStatus id={info.row.original.id} type="forfait" />
        </Flex>
      ),
    }),
    columnHelper.accessor("client", {
      id: "client",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Client
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            <FieldClient
              id={info.row.original.idclient}
              field="raison_sociale"
              context="forfait"
            />
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("prix", {
      id: "prix",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Prix
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()} € HT
        </Text>
      ),
    }),
    columnHelper.accessor("remarque_tech", {
      id: "remarque_tech",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Remarque
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("temps", {
      id: "temps",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Temps
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() ? numberToHour(info.getValue()) : ""}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("type", {
      id: "type",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Type
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Icon
            w="24px"
            h="24px"
            me="5px"
            color={
              info.getValue() === 1
                ? "green.500"
                : info.getValue() === 2
                ? "red.500"
                : null
            }
            as={
              info.getValue() === 1
                ? MdHourglassBottom
                : info.getValue() === 2
                ? MdCalendarMonth
                : null
            }
          />
          <Spacer />
          <ListMenuForfait
            forfaitrow={[info.row.original]}
            openmodal={onOpen}
            setselectedforfait={setSelectedForfait}
            setData={applyFilter}
            setFormModal={setFormModal}
          />
        </Flex>
      ),
    }),
  ];
  const [data, setData] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  function handleAdd() {
    setSelectedForfait(null);
    onOpen();
  }
  const { filterStatus, setFilterStatus } = useForfaitFilter();

  const applyFilter = React.useCallback(
    (newData: any[]) => {
      let filteredData = newData;

      if (filterStatus === "true") {
        filteredData = newData.filter(
          (forfaits: { statut: boolean }) => forfaits.statut === true
        );
      } else if (filterStatus === "false") {
        filteredData = newData.filter(
          (forfaits: { statut: boolean }) => forfaits.statut === false
        );
      }

      setData(filteredData);
    },
    [filterStatus]
  );

  React.useEffect(() => {
    applyFilter(tableData);
  }, [filterStatus, tableData, applyFilter]);

  function handleChangeStatut(event: React.ChangeEvent<HTMLSelectElement>) {
    const value = event.target.value;
    setFilterStatus(value);
    applyFilter(tableData);
  }

  React.useEffect(() => {
    table.setSorting([{ id: "date_debut", desc: true }]);
  }, [table]);
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          mb="4px"
          fontWeight="700"
          lineHeight="100%"
        >
          Liste des Forfaits ({data.length})
        </Text>
        <Select
          placeholder="Selection statut"
          width={450}
          onChange={handleChangeStatut}
        >
          <option value={""}>Tout</option>
          <option value={"true"}>Actif</option>
          <option value={"false"}>Inactif</option>
        </Select>
        <Button
          variant="darkBrand"
          color="white"
          fontSize="sm"
          fontWeight="500"
          borderRadius="70px"
          px="24px"
          py="5px"
          leftIcon={<MdAdd />}
          onClick={handleAdd}
        >
          Ajouter un Forfait
        </Button>
      </Flex>
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table
              .getRowModel()
              .rows.slice(0, 100)
              .map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
      <ModalForm<ForfaitType>
        isOpen={isOpen}
        onClose={onClose}
        newData={(updatedData) => applyFilter(updatedData)}
        updateData={selectedForfait}
        form={formModal}
      />
    </Card>
  );
}
