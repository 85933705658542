import React, { createContext, useContext, useState } from "react";

type FilterForfaitContextType = {
  filterStatus: string;
  setFilterStatus: (status: string) => void;
};

const FilterForfaitContext = createContext<
  FilterForfaitContextType | undefined
>(undefined);

export function FilterForfaitProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [filterStatus, setFilterStatus] = useState("");

  return (
    <FilterForfaitContext.Provider value={{ filterStatus, setFilterStatus }}>
      {children}
    </FilterForfaitContext.Provider>
  );
}

export function useForfaitFilter() {
  const context = useContext(FilterForfaitContext);
  if (context === undefined) {
    throw new Error(
      "useForfaitFilter must be used within a FilterForfaitProvider"
    );
  }
  return context;
}
